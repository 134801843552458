import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resourceInstructions", "resourceSelect", "modal", "fileInput"]

  connect() {
    console.log("Virtual Writer Form controller connected")
    this.updateResourceInstructionsRequirement()
  }

  // Make resource instructions required if resources are selected or files are uploaded
  updateResourceInstructionsRequirement() {
    const hasSelectedResources = this.resourceSelectTarget.value.length > 0
    
    if (hasSelectedResources) {
      this.resourceInstructionsTarget.required = true
    } else {
      this.resourceInstructionsTarget.required = false
    }
  }

  // Called when resources are selected from dropdown
  resourceSelected() {
    this.updateResourceInstructionsRequirement()
  }

  // Called when files are selected for upload
  filesSelected() {
    this.updateResourceInstructionsRequirement()
  }

  openUploadModal(event) {
    event.preventDefault()
    this.modalTarget.classList.remove("hidden")
  }

  closeUploadModal() {
    this.modalTarget.classList.add("hidden")
    this.fileInputTarget.value = ""
  }

  async handleResourceUpload(event) {
    event.preventDefault()
    
    const formData = new FormData(event.target)
    
    try {
      const response = await fetch('/resources/upload_from_modal', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        credentials: 'same-origin'
      })
      
      if (response.ok) {
        const data = await response.json()
        // Update the select2 field with new resources
        const select2Element = this.resourceSelectTarget
        
        data.resources.forEach(resource => {
          const option = new Option(
            `${resource.name || resource.filename} (${resource.word_count} words)`,
            resource.id,
            false,
            true // Selected by default
          )
          select2Element.add(option)
        })
        
        $(select2Element).trigger('change') // Refresh Select2
        this.closeUploadModal()
      } else {
        console.error('Upload failed:', await response.text())
      }
    } catch (error) {
      console.error('Error uploading resources:', error)
    }
  }

  switchTab(event) {
    const tabName = event.currentTarget.dataset.tab;
    
    // Update tab buttons
    this.element.querySelectorAll('.tab-button').forEach(button => {
      if (button.dataset.tab === tabName) {
        button.classList.add('border-red-500', 'text-red-600');
        button.classList.remove('border-transparent', 'text-gray-500');
      } else {
        button.classList.remove('border-red-500', 'text-red-600');
        button.classList.add('border-transparent', 'text-gray-500');
      }
    });

    // Show/hide content
    this.element.querySelectorAll('.tab-content').forEach(content => {
      content.classList.add('hidden');
    });
    this.targets.find(`${tabName}Tab`).classList.remove('hidden');
  }

  async handleUrlImport(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        // Update the resource select with the new resource
        this.updateResourceSelect(data.resources);
        this.closeUploadModal();
      } else {
        const error = await response.json();
        alert(error.error || 'Failed to import URL');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to import URL');
    }
  }

  async handleTextImport(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        // Update the resource select with the new resource
        this.updateResourceSelect(data.resources);
        this.closeUploadModal();
      } else {
        const error = await response.json();
        alert(error.error || 'Failed to import text');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to import text');
    }
  }

  updateResourceSelect(resources) {
    const select2Element = this.resourceSelectTarget;
    
    resources.forEach(resource => {
      const option = new Option(
        `${resource.name || resource.filename} (${resource.word_count} words)`,
        resource.id,
        false,
        true // Selected by default
      );
      select2Element.add(option);
    });
    
    $(select2Element).trigger('change'); // Refresh Select2
  }

  handleSubmit(event) {
    event.preventDefault()
    const form = event.target.closest('form')
    
    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        "Accept": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Send message to opener window
        if (window.opener) {
          window.opener.postMessage({
            type: "newVirtualWriterProfile",
            profile: {
              id: data.profile.id,
              name: data.profile.name
            }
          }, "*")
        }
        // Close this window
        window.close()
      }
    })
  }
} 