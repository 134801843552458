import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["profileSelect"]

  connect() {
    window.addEventListener("message", this.handleMessage.bind(this))
  }

  disconnect() {
    window.removeEventListener("message", this.handleMessage.bind(this))
  }

  handleMessage(event) {
    // Only handle messages about new virtual writer profiles
    if (event.data.type === "newVirtualWriterProfile") {
      const profile = event.data.profile
      
      // Add the new profile to the select dropdown
      const select = this.profileSelectTarget
      const option = new Option(profile.name, profile.id, false, true)
      select.add(option)
      
      // Trigger change event for any dependent behaviors
      select.dispatchEvent(new Event('change'))
    }
  }
} 