import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]
  
  connect() {
    this.updateTotalWordCount()
    
    // Initialize Select2 change event listener
    $(this.selectTarget).on('select2:select select2:unselect', () => {
      this.updateTotalWordCount()
    })
  }
  
  updateTotalWordCount() {
    const select = this.selectTarget
    const wordCounts = JSON.parse(select.dataset.wordCounts)
    const selectedValues = $(select).val() || [] // Get selected values from Select2
    
    const totalWords = selectedValues.reduce((sum, value) => {
      const resourceId = parseInt(value)
      return sum + (wordCounts[resourceId] || 0)
    }, 0)
    
    document.getElementById('total-word-count').textContent = totalWords.toLocaleString()
  }
  
  disconnect() {
    $(this.selectTarget).off('select2:select select2:unselect')
  }
}
