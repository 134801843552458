import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    const resourceId = event.target.closest("form").dataset.resourceId
    const speakerCount = parseInt(event.target.value)
    const speakerNamesDiv = document.getElementById(`speaker_names_${resourceId}`)
    
    if (speakerCount > 0) {
      speakerNamesDiv.innerHTML = ""
      for (let i = 0; i < speakerCount; i++) {
        speakerNamesDiv.insertAdjacentHTML("beforeend", this.speakerFieldTemplate(i + 1))
      }
      speakerNamesDiv.classList.remove("hidden")
    } else {
      // If "Auto" is selected (value is null or empty)
      speakerNamesDiv.classList.add("hidden")
      speakerNamesDiv.innerHTML = ""
    }
  }

  speakerFieldTemplate(number) {
    return `
      <div class="mb-3">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Speaker ${number}</label>
        <input type="text" 
               name="resource[speaker_${number}]" 
               placeholder="(optional)" 
               class="form-input w-full bg-white dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500">
      </div>
    `
  }
} 